<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">
        How to define a Network Penetration Testing?
      </h2>
      <p class="desc">
        A network penetration test mainly aims at detecting bugs in networks,
        protocols, host applications, and network appliances (i.e., routers,
        switches), so hackers can discover and manipulate the bugs. A network
        penetration test can provide hackers with real-world chances to
        jeopardize devices and networks and enable unwanted exposure to
        confidential details or control devices for suspicious/non – commercial
        motives.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/network-penetration/physicalsecurity-2016-rounded_v1-500x500-1.png"
        alt="How to define a Network Penetration Testing?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-net-penet-section",
};
</script>
