<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Approach</h2>
      <p class="desc">
        A robust risk-based methodology to detect essential network-centered
        weaknesses, which arise on all networks, applications, and hosts, is
        implemented in the Ejaf Security Platform Penetration Testing Program.
      </p>
      <ol>
        <li>
          <p>Set of knowledge</p>
        </li>
        <li>
          <p>Designing of hazard</p>
        </li>
        <li>
          <p>Evaluation of susceptibility</p>
        </li>
        <li>
          <p>Implementation</p>
        </li>
        <li>
          <p>Post-implementation</p>
        </li>
        <li>
          <p>Statistics</p>
        </li>
      </ol>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/network-penetration/approach.jpg"
        alt="Approach"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "seventh-net-penet-section",
};
</script>
