<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">Methodology</h2>
      <p class="desc">
        The widely understood and market-standard structures are used for every
        network penetration testing. Ejaf Technology utilizes industry-standard
        systems to include a regular and thorough penetration check to perform
        network penetration tests. At the very least, even outside this original
        structure, this methodology is dependent on the Penetration Testing
        Execution Standard (PTES).
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/network-penetration/transform-your-business-ejaf-tech-canada-1.jpg"
        alt="Methodology"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fiveth-net-penet-section",
};
</script>
