<template>
  <div class="modifying my-5 py-5 text-center">
    <ul class="row">
      <li class="col-lg-1 mt-5"></li>
      <li class="col-lg-2 mt-5">
        <i class="fa-solid fa-magnifying-glass fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Intelligence Gathering</h4>
      </li>
      <li class="col-lg-2 mt-5">
        <i class="fa-solid fa-unlock fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Threat Modeling</h4>
      </li>
      <li class="col-lg-2 mt-5">
        <i class="fa-solid fa-server fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Vulnerability Analysis</h4>
      </li>
      <li class="col-lg-2 mt-5">
        <i class="fa-solid fa-triangle-exclamation fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Exploitation</h4>
      </li>
      <li class="col-lg-2 mt-5">
        <i class="fa-solid fa-file fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Reporting</h4>
      </li>
      <li class="col-lg-1 mt-5"></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "sixth-net-penet-section",
};
</script>
