<template>
  <network-penetration />
</template>

<script>
import NetworkPenetration from "../../components/services/NetworkPenetration.vue";
export default {
  components: { NetworkPenetration },
};
</script>
