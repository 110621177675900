<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Deliverable</h2>
      <p class="desc">
        We find the implementation/monitoring process to be the most critical at
        Ejaf Technology Security. We take special care to ensure that we have
        entirely conveyed the importance of our services. The produced report
        contains many essential points: executive summary, context, conclusions,
        facts, methods and techniques. A simplistic file in CSV file format is
        also supplied to improve the adjustment and strategic planning of any
        recognized studies concerning the document.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/network-penetration/deliverable-ejaf-tech-canada.png"
        alt="Deliverable"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fourth-net-penet-section",
};
</script>
