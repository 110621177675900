<template>
  <div class="modifying my-5 py-5 text-center">
    <ul class="row">
      <li class="col-lg-3 mt-5">
        <i class="fa-solid fa-bullseye fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Approach</h4>
      </li>
      <li class="col-lg-3 mt-5">
        <i class="fa-solid fa-list-ul fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Manual Testing vs Automated</h4>
      </li>
      <li class="col-lg-3 mt-5">
        <i class="fa-solid fa-gears fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Tools</h4>
      </li>
      <li class="col-lg-3 mt-5">
        <i class="fa-solid fa-file fs-1 icon"></i>
        <h4 class="title-yellow mt-3">Reporting</h4>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "third-net-penet-section",
};
</script>
