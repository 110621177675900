<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">Intelligence Gathering</h2>
      <p class="desc mb-2">
        The process of collecting knowledge involves device identification,
        visualization of networks, banner identification and much more. Server
        and application exploration activities contribute to a consolidated
        collection of open platforms and their resources to collect as much
        knowledge as necessary about networks.
      </p>
      <p class="desc">
        Initial domain footprinting, live network identification and device
        listing and system software and software fingerprinting involve the
        client and software exploration. The aim is to trace and arrange for the
        detection of threats jointly.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/network-penetration/documentation3-ejaf-tech-canada.jpg"
        alt="Intelligence Gathering"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "eighth-net-penet-section",
};
</script>
