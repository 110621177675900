<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1">What is our methodology?</h2>
      <p class="desc">
        An attack simulation carried out by our highly trained security
        consultants is part of our network penetration test methodology to:
      </p>
      <ul class="desc">
        <li>
          <p>Identify environmental safety weaknesses</p>
        </li>
        <li>
          <p>Know the institution’s degree of vulnerability</p>
        </li>
        <li>
          <p>
            Discuss support and resolve network protection vulnerabilities found
          </p>
        </li>
      </ul>
      <p class="desc">
        The penetration testers at Ejaf Security Network have vast expertise in
        breaking the network, systems, and hosts and supporting them. They use
        this expertise in crucial matters to focus on the matter and provide
        practical guidance for remediation.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../assets/network-penetration/methodology.jpg"
        alt="What is our methodology?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-net-penet-section",
};
</script>
